<!-- =========================================================================================
	File Name: TableMiscellaneous.vue
	Description: Combine filter,sorter, pagination etc.
	----------------------------------------------------------------------------------------
	Item Name: Vuesax Admin - VueJS Dashboard Admin Template
	Version: 1.1
	Author: Pixinvent
	Author URL: hhttp://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vx-card>
    <!-- HEADER -->
    <div
      class="grid-layout-container alignment-block"
      style="font-family: SukhumvitSet-Medium; vertical-align:text-top ;width:100%; padding: 30px ; margin-bottom: 20px;"
    >
      <vs-row vs-w="12">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="3"
          vs-sm="3"
          vs-xs="3"
        >
          <!-- เลข order -->
          <vs-row vs-w="12" class="the-blue">
            <vs-col
              class="theLabel"
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-lg="5"
              vs-sm="5"
              vs-xs="5"
            >เลข order</vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-lg="6"
              vs-sm="6"
              vs-xs="6"
            >{{this.orderNumber}}</vs-col>
          </vs-row>
          <!-- กำหนดรับ -->
          <vs-row vs-w="12">
            <vs-col
              class="theLabel"
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-lg="5"
              vs-sm="5"
              vs-xs="5"
            >กำหนดรับ</vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-lg="6"
              vs-sm="6"
              vs-xs="6"
            >2019-03-15</vs-col>
          </vs-row>
          <!-- กำหนดคืน -->
          <vs-row vs-w="12">
            <vs-col
              class="theLabel"
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-lg="5"
              vs-sm="5"
              vs-xs="5"
            >กำหนดคืน</vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-lg="6"
              vs-sm="6"
              vs-xs="6"
            >2019-03-24</vs-col>
          </vs-row>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="5"
          vs-sm="5"
          vs-xs="5"
        >
          <vs-row vs-w="12">
            <!-- ชื่อผู้เช่า -->
            <vs-row vs-w="12">
              <vs-col
                class="theLabel"
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="3"
                vs-sm="3"
                vs-xs="3"
              >ชื่อผู้เช่า</vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
              >คุณแนน</vs-col>
            </vs-row>
            <!-- เบอร์ติดต่อ -->
            <vs-row vs-w="12">
              <vs-col
                class="theLabel"
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="3"
                vs-sm="3"
                vs-xs="3"
              >เบอร์ติดต่อ</vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
              >0819259505</vs-col>
            </vs-row>
            <!-- ที่อยู่ -->
            <vs-row vs-w="12">
              <vs-col
                class="theLabel"
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="3"
                vs-sm="3"
                vs-xs="3"
              >ที่อยู่</vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="9"
                vs-sm="9"
                vs-xs="9"
              >20 สุขุมวิท อ.เมือง จ.ระยอง ไทย</vs-col>
            </vs-row>
          </vs-row>
        </vs-col>
      </vs-row>
    </div>

    <!-- ตาราง -->
    <vs-table hoverFlat stripe :data="orders">
      <template slot="thead">
        <vs-th>รูปตัวอย่าง</vs-th>
        <vs-th style="width:200px;" sort-key="itemName">ชื่อสินค้า</vs-th>
        <vs-th sort-key="reservedQuantity">จำนวน</vs-th>
        <vs-th style="text-align:center;" sort-key="rentalPrice">ราคาเช่า</vs-th>
        <vs-th style="text-align:center;" sort-key="bail">เงินประกัน</vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr class="table-font" :data="tr" :key="indextr" v-for="(tr, indextr) in orders">
          <vs-td :data="data[indextr].itemimage">
            <img
              class="responsive rounded"
              :src="data[indextr].itemimage"
              alt="user-upload"
              style="width:100px;"
            />
          </vs-td>

          <vs-td :data="data[indextr].itemName">{{data[indextr].itemName}}</vs-td>

          <vs-td :data="data[indextr].reservedQuantity">{{data[indextr].reservedQuantity}}</vs-td>

          <vs-td :data="data[indextr].rentalPrice">
            <span
              style="padding:14px 10px;"
            >{{formatPrice(data[indextr].rentalPrice * data[indextr].reservedQuantity)}}</span>
          </vs-td>

          <vs-td :data="data[indextr].bail">
            <span
              style="padding:14px 10px;"
            >{{formatPrice(data[indextr].bail * data[indextr].reservedQuantity)}}</span>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <!-- BEGIN: MONETARY SUMMATION -->
    <div class="grid-layout-container alignment-block" style="width:100%; margin-top: 20px;">
      <!-- รวมค่าเช่า -->
      <vs-row vs-w="12" style="padding: 10px 20px; " class="grand-total the-black">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-lg="2"
          vs-sm="2"
          vs-xs="4"
          vs-offset="8"
        >ส่วนลด</vs-col>
        <vs-col
          style="padding-left: 30px;  "
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="2"
          vs-sm="2"
          vs-xs="4"
        >{{formatPrice(rentalPriceTotal)}} บาท</vs-col>
      </vs-row>
      <!-- ส่วนลดค่าเช่า -->
      <vs-row
        v-if="rentalDiscount > 0 && rentalDiscount != undefined && rentalDiscount!= null"
        vs-w="12"
        style="padding: 0 20px; "
        class="grand-total-discount the-red"
      >
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-lg="2"
          vs-sm="2"
          vs-xs="4"
          vs-offset="8"
          style="  "
        >ส่วนลดค่าเช่า</vs-col>
        <vs-col
          style="padding-left: 30px;  "
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="2"
          vs-sm="2"
          vs-xs="4"
        >{{formatPrice(rentalDiscount)}} บาท</vs-col>
      </vs-row>
      <!-- ขีดเส้นใต้ -->
      <vs-row vs-w="12" style="height: 10px !important;">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-lg="2"
          vs-sm="2"
          vs-xs="4"
          vs-offset="8"
          style="border-bottom: 1px solid #f4f4f4; "
        >&nbsp;</vs-col>
        <vs-col
          style="padding-left: 30px; border-bottom: 1px solid #f4f4f4; "
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="2"
          vs-sm="2"
          vs-xs="4"
        >&nbsp;</vs-col>
      </vs-row>
      <!-- รวมค่าประกัน -->
      <vs-row vs-w="12" style="padding: 10px 20px; " class="grand-total the-black">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-lg="2"
          vs-sm="2"
          vs-xs="4"
          vs-offset="8"
          style=" padding-top: 20px; "
        >ยอดรวมค่าประกัน</vs-col>
        <vs-col
          style="padding-left: 30px;  padding-top: 20px; "
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="2"
          vs-sm="2"
          vs-xs="4"
        >{{formatPrice(bailTotal)}} บาท</vs-col>
      </vs-row>
      <!-- ส่วนลดค่าประกัน -->
      <vs-row vs-w="12" style="padding: 0 20px; " class="grand-total-discount the-red">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-lg="2"
          vs-sm="2"
          vs-xs="4"
          vs-offset="8"
          style="  "
        >ส่วนลดค่าประกัน</vs-col>
        <vs-col
          style="padding-left: 30px;  "
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="2"
          vs-sm="2"
          vs-xs="4"
        >{{formatPrice(bailDiscount)}} บาท</vs-col>
      </vs-row>
      <!-- ขีดเส้นใต้ -->
      <vs-row vs-w="12" style="height: 10px !important;">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-lg="2"
          vs-sm="2"
          vs-xs="4"
          vs-offset="8"
          style="border-bottom: 1px solid #f4f4f4; "
        >&nbsp;</vs-col>
        <vs-col
          style="padding-left: 30px; border-bottom: 1px solid #f4f4f4; "
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="2"
          vs-sm="2"
          vs-xs="4"
        >&nbsp;</vs-col>
      </vs-row>
      <!-- ส่วนลดอื่นๆ -->
      <vs-row
        vs-w="12"
        style="padding: 0 20px;margin-top:15px;"
        class="grand-total-discount the-red"
      >
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-lg="2"
          vs-sm="2"
          vs-xs="4"
          vs-offset="8"
          style="padding-top: 20px;  "
        >ส่วนลดอื่นๆ</vs-col>
        <vs-col
          style="padding-left: 30px;  padding-top: 20px; "
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="2"
          vs-sm="2"
          vs-xs="4"
        >{{formatPrice(promotionDiscount)}} บาท</vs-col>
      </vs-row>
      <!-- ขีดเส้นใต้ -->
      <vs-row vs-w="12" style="height: 10px !important;">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-lg="2"
          vs-sm="2"
          vs-xs="4"
          vs-offset="8"
          style="border-bottom: 1px solid #f4f4f4; padding:0;height:15px;"
        >&nbsp;</vs-col>
        <vs-col
          style="padding-left: 30px; border-bottom: 1px solid #f4f4f4; padding:0;height:15px;"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="2"
          vs-sm="2"
          vs-xs="4"
        >&nbsp;</vs-col>
      </vs-row>
      <!-- ยอดรวมทั้งหมด -->
      <vs-row vs-w="12" style="padding: 0 20px; margin-top:30px; " class="grand-total the-blue">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-lg="2"
          vs-sm="2"
          vs-xs="4"
          vs-offset="8"
        >ยอดรวมทั้งหมด</vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="2"
          vs-sm="2"
          vs-xs="4"
          style="padding-left:30px;"
        >{{formatPrice(grandTotal)}} บาท</vs-col>
      </vs-row>
      <!-- ขีดเส้นใต้ -->
      <vs-row vs-w="12" style="height: 10px !important;">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-lg="2"
          vs-sm="2"
          vs-xs="4"
          vs-offset="8"
          style="border-bottom: 4px solid #f0f0f0; padding:0;height:20px;"
        >&nbsp;</vs-col>
        <vs-col
          style="border-bottom: 4px solid #f0f0f0;  padding-left: 30px;  padding:0;height:20px;"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="2"
          vs-sm="2"
          vs-xs="4"
        >&nbsp;</vs-col>
      </vs-row>
      <!-- เว้นช่องไฟ -->
      <vs-row vs-w="12" style="margin-top:20px; height: 20px !important;">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-lg="2"
          vs-sm="2"
          vs-xs="4"
          vs-offset="8"
          style=" padding:0;height:15px;"
        >&nbsp;</vs-col>
        <vs-col
          style="padding-left: 30px;  padding:0;height:15px;"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="2"
          vs-sm="2"
          vs-xs="4"
        >&nbsp;</vs-col>
      </vs-row>
    </div>
    <!-- END: MONETARY SUMMATION -->

    <!--------------------------------------------------------------------->
    <vs-row vs-w="12" style="margin-top:30px;">
      <div style="width:100%; height:5px; background-color:#7AE7FF;"></div>
    </vs-row>
    <!--------------------------------------------------------------------->
    <!-- BEGIN: RETURN DETAIL -->

    <div
      class="grid-layout-container alignment-block normal-font my-container"
      style=" width:100%;"
    >
      <h3 style="padding-left : 30px; margin-top 50px;">ข้อมูลเพิ่มเติมในการคืนชุด</h3>
      <!--  โน้ต/หมายเหตุ  -->
      <vs-row style="margin-top:30px;" class="row-form" vs-w="12">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-lg="4"
          vs-sm="4"
          vs-xs="4"
          class="form-label px-2"
        >โน้ต/หมายเหตุ</vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="6"
          vs-sm="6"
          vs-xs="6"
          class="normal-font padding-left-30"
        >
          <pre style="font-family: SukhumvitSet-Medium;">{{remark}}</pre>
        </vs-col>
      </vs-row>
      <!--  หักเงินประกัน  -->
      <vs-row style="margin-top:30px;" class="row-form" vs-w="12">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-lg="4"
          vs-sm="4"
          vs-xs="4"
          class="form-label px-2"
        >หักเงินประกัน</vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="6"
          vs-sm="6"
          vs-xs="6"
          class="normal-font padding-left-30"
        >
          {{deduction}}
          <br />
          <div
            class="little-comment the-blue"
          >(วงเงินประกัน {{formatPrice(bailTotal - bailDiscount)}} บาท)</div>
        </vs-col>
      </vs-row>

      <!--  ลงวันที่หักเงินประกัน  -->
      <vs-row style="margin-top:30px;" class="row-form" vs-w="12">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-lg="4"
          vs-sm="4"
          vs-xs="4"
          class="form-label px-2"
        >ลงวันที่หักเงินประกัน</vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="6"
          vs-sm="6"
          vs-xs="6"
          class="normal-font padding-left-30"
        >{{formatDate(deducingDate)}}</vs-col>
      </vs-row>
    </div>
    <!-- END: RETURN DETAIL -->

    <!--------------------------------------------------------------------->
    <vs-row vs-w="12" style="margin-top:30px;">
      <div style="width:100%; height:5px; background-color:#7AE7FF;"></div>
    </vs-row>
    <!--------------------------------------------------------------------->
    <!-- BEGIN: RETURN DETAIL -->

    <div
      class="grid-layout-container alignment-block normal-font padding-left-30 my-container"
      style=" width:100%;"
    >
      <h3 style="padding-left : 30px; margin-top 50px;">ข้อมูลการโอนเงิน</h3>
      <!--  สรุปยอดเงินประกันที่ต้องโอนคืน  -->
      <vs-row style="margin-top:30px;" class="row-form" vs-w="12">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-lg="4"
          vs-sm="4"
          vs-xs="4"
          class="form-label px-2"
        >สรุปยอดเงินประกันที่ต้องโอนคืน</vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="6"
          vs-sm="6"
          vs-xs="6"
          class="normal-font padding-left-30"
        >{{formatPrice(bailTotal - bailDiscount - deduction)}}</vs-col>
      </vs-row>

      <!--  ธนาคาร  -->
      <vs-row style=" margin-top:30px;  margin-bottom:10px;" vs-w="12">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-lg="4"
          vs-sm="4"
          vs-xs="4"
          class="form-label px-2"
        >ธนาคาร</vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="6"
          vs-sm="6"
          vs-xs="6"
          class="normal-font padding-left-30"
        >{{bankName}}</vs-col>
      </vs-row>

      <!--  ชื่อบัญชีลูกค้า  -->
      <vs-row style=" margin-top:10px; margin-bottom:10px;" vs-w="12">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-lg="4"
          vs-sm="4"
          vs-xs="4"
          class="form-label px-2"
        >ชื่อบัญชีลูกค้า</vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="6"
          vs-sm="6"
          vs-xs="6"
          class="normal-font padding-left-30"
        >{{accountName}}</vs-col>
      </vs-row>

      <!--  เลขที่บัญชีลูกค้า  -->
      <vs-row style=" margin-top:10px; margin-bottom:10px;" vs-w="12">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-lg="4"
          vs-sm="4"
          vs-xs="4"
          class="form-label px-2"
        >เลขที่บัญชีลูกค้า</vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="6"
          vs-sm="6"
          vs-xs="6"
          class="normal-font padding-left-30"
        >{{accountNumber}}</vs-col>
      </vs-row>

      <!--  เบอร์ติดต่อ  -->
      <vs-row style=" margin-top:10px; margin-bottom:40px;" vs-w="12">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-lg="4"
          vs-sm="4"
          vs-xs="4"
          class="form-label px-2"
        >เบอร์ติดต่อ</vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="6"
          vs-sm="6"
          vs-xs="6"
          class="normal-font padding-left-30"
        >{{transferContact}}</vs-col>
      </vs-row>

      <!--  UPLOAD FIELD  -->
      <vs-row style=" margin-top:10px; margin-bottom:40px;" vs-w="12">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-lg="4"
          vs-sm="4"
          vs-xs="4"
          class="form-label px-2"
        >อัพโหลดสลิปการโอนคืน</vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="6"
          vs-sm="6"
          vs-xs="6"
          class="normal-font padding-left-30"
        >{{transferContact}}</vs-col>
      </vs-row>
    </div>
    <!-- END: RETURN DETAIL -->

    <!--------------------------------------------------------------------->
    <div style="border-top: 1px solid #E3E3E3; margin-bottom:40px; "></div>
    <!--------------------------------------------------------------------->

    <!--  ปุ่ม -->
    <vs-row vs-w="12" vs-type="flex" vs-justify="center">
      <vs-button
        @click="$router.back()"
        style="margin-right: 20px;"
        color="warning"
        type="filled"
      >กลับไปหน้าเช่า</vs-button>
      <vs-button color="success" type="filled">ยืนยันการเช่า</vs-button>
    </vs-row>
    <!--------------------------------------------------------------------->
  </vx-card>
</template>

<script>
import Prism from "vue-prism-component";
import Datepicker from "vuejs-datepicker";
import { Validator } from "vee-validate";

import CurrencyValue from "./Components/CurrencyValue";
import shapeFormat from "../../mixins/shapeFormat.js";

const dict = {
  custom: {
    name: {
      required: "ยังไม่ได้ใส่ชื่อลูกค้า"
    }
  }
};

Validator.localize("en", dict);

export default {
  mixins: [shapeFormat],
  data() {
    return {
      // 1. ORDER HEADER
      orderNumber: "",
      orderStatus: 0, // 0: darft, 1: saved, 2: waiting for receiving, 3: waiting for returning, 4: waiting for money back, 5: closed
      remark: "",

      // 2. CUSTOMER
      customerInfo: "",
      customerName: "",
      customerAddress: "",
      customerPhone: "",
      accountNumber: "",
      accountName: "",
      bankName: "",
      transferContact: "",

      // 3. DELIVERY
      howtoreceive: "มารับเอง", // 1 :มารับเอง, 2: ผ่านเมสเซนเจอร์, 3: ผ่าน EMS / Kerry
      receivingDate: null,
      returnDate: null,
      addtocart: "",

      // 4. MONETARY
      rentalPriceTotal: 0,
      bailTotal: 0,
      grandTotal: 0,
      bailDiscount: 0,
      rentalDiscount: 0,
      promotionDiscount: 0,
      rentalDiscountPercent: 0,
      bailDiscountPercent: 0,
      deduction: 0,
      deducingDate: null,

      // 5. LIST
      originalList: [],
      select_order: "OR1903130042",
      order_option: [
        { text: "OR1903130042", value: "OR1903130042" },
        { text: "OR1903130041", value: "OR1903130041" },
        { text: "OR1903130040", value: "OR1903130040" }
      ],
      orders: []
      // orders: [
      //   {
      //     itemimage: require("@/assets/images/itemimage/3cwf167S.jpg"),
      //     itemName: "3cwf167S",
      //     reservedQuantity: 1,
      //     rentalPrice: 1200,
      //     bail: 1200
      //   },
      //   {
      //     itemimage: require("@/assets/images/itemimage/CWF018M.jpg"),
      //     itemName: "CWF018M",
      //     reservedQuantity: 1,
      //     rentalPrice: 1200,
      //     bail: 1200
      //   },
      //   {
      //     itemimage: require("@/assets/images/itemimage/CWF019L.jpg"),
      //     itemName: "CWF019L",
      //     reservedQuantity: 1,
      //     rentalPrice: 1200,
      //     bail: 1200
      //   },
      //   {
      //     itemimage: require("@/assets/images/itemimage/CWF022M.jpg"),
      //     itemName: "CWF022M",
      //     reservedQuantity: 1,
      //     rentalPrice: 1200,
      //     bail: 1200
      //   },
      //   {
      //     itemimage: require("@/assets/images/itemimage/CWF025L.jpg"),
      //     itemName: "CWF025L",
      //     reservedQuantity: 1,
      //     rentalPrice: 2000,
      //     bail: 2000
      //   },
      //   {
      //     itemimage: require("@/assets/images/itemimage/CWF0164XL.jpg"),
      //     itemName: "CWF0164XL",
      //     reservedQuantity: 1,
      //     rentalPrice: 1200,
      //     bail: 1200
      //   },
      //   {
      //     itemimage: require("@/assets/images/itemimage/3cwf167S.jpg"),
      //     itemName: "3cwf167S",
      //     reservedQuantity: 1,
      //     rentalPrice: 1200,
      //     bail: 1200
      //   }
      // ]
    };
  },

  created: function() {
    // var rp = this.$route.params.orderno;
    // alert(rp);

    // INITIALIZE DATA

    // 1. ORDER HEADER
    this.orderNumber = this.$route.params.orderno;
    this.orderStatus = 0; // 0: darft, 1: saved, 2: waiting for receiving, 3: waiting for returning, 4: waiting for money back, 5: closed
    this.remark =
      " รายละเอียดเพิ่มเติมจาก Staff ในการคืนชุด \n ปัญหาและอุปสรรคในการให้บริการ";

    // 2. CUSTOMER
    this.customerInfo = "";
    this.customerName = "";
    this.customerAddress = "";
    this.customerPhone = "";

    // 3. DELIVERY
    this.howtoreceive = "มารับเอง"; // 1 :มารับเอง, 2: ผ่านเมสเซนเจอร์, 3: ผ่าน EMS / Kerry
    this.receivingDate = null;
    this.returnDate = null;
    this.addtocart = "";

    // 4. MONETARY

    this.bailDiscount = 300;
    this.rentalDiscount = 100;
    this.promotionDiscount = 1000;
    this.deduction = 500;
    this.deducingDate = new Date("2019-03-24");
    this.accountNumber = "19204837180";
    this.accountName = "สมหญิง จรัสแสง";
    this.bankName = "กสิกรไทย";
    this.transferContact = "0899189888";

    this.rentalPriceTotal = 0;
    this.bailTotal = 0;
    this.grandTotal = 0;

    for (var i = 0; i < this.orders.length; i++) {
      this.rentalPriceTotal =
        this.rentalPriceTotal +
        this.orders[i].rentalPrice * this.orders[i].reservedQuantity;

      this.bailTotal =
        this.bailTotal + this.orders[i].bail * this.orders[i].reservedQuantity;
    }
    this.grandTotal = this.grandTotal + this.rentalPriceTotal + this.bailTotal;

    console.log("this.grandTotal >>> ", this.grandTotal);
  },
  components: {
    Prism,
    Datepicker,
    CurrencyValue
  },
  mounted: function() {
    this.$nextTick(function() {
      // this.$refs.addcart.$el.focus();
    });
  },
  computed: {
    totalRental: function() {
      var total = 0;
      for (var i = 0; i < this.orders.length; i++) {
        total += this.orders[i].rentalPrice;
      }
      return total;
    }
  },
  methods: {
    // goback
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    setFocus: function() {
      // Note, you need to add a ref="search" attribute to your input.
      // this.$refs.addcart.$el.focus();
    },

    lineTotal: function(rentalPrice, bail) {
      if (!Number.isFinite(rentalPrice)) rentalPrice = 0;
      if (!Number.isFinite(bail)) rentalPrice = 0;
      return rentalPrice + bail;
    },
    removeItem: function(indextr) {
      this.orders[indextr].reservedQuantity = 1;
      this.orders.splice(indextr, 1);
      console.log(this.orders);
    },
    setSelect: function() {
      event.target.setSelectionRange(0, this.addtocart.length);
    }
  },
  watch: {
    addtocart: function(newvalue) {
      var newItem = this.itemsInStock.find(function(ele) {
        if (ele.itemName === newvalue) {
          return ele;
        }
      });
      if (newItem) {
        event.target.setSelectionRange(0, this.addtocart.length);

        var foundindex = this.displayItems.findIndex(
          x => x.itemName === newItem.itemName
        );

        if (foundindex < 0) this.displayItems.push(newItem);
        else {
          this.displayItems[foundindex].reservedQuantity++;
        }

        // setTimeout(function() {
        //   this.addtocart = "";
        // }, 2000);

        this.addtocart = "";
      }
    },

    orders: {
      handler() {
        this.rentalPriceTotal = 0;
        this.bailTotal = 0;
        this.grandTotal = 0;

        for (var i = 0; i < this.orders.length; i++) {
          this.rentalPriceTotal =
            this.rentalPriceTotal +
            this.orders[i].rentalPrice * this.orders[i].reservedQuantity;

          this.bailTotal =
            this.bailTotal +
            this.orders[i].rentalPrice * this.orders[i].reservedQuantity;
        }
        this.grandTotal =
          this.grandTotal + this.rentalPriceTotal + this.bailTotal;

        console.log("this.grandTotal >>> ", this.grandTotal);
      },
      deep: true
    }
  }
};
</script>

<style>
input {
  width: 100%;
}
.theLabel {
  font-weight: bold;
}

.vs-col {
  flex-wrap: wrap;
}
.row-form {
  margin-bottom: 30px;
}
.form-label {
  font-weight: bold;
}

.grand-total {
  font-weight: bold;
  font-family: "SukhumvitSet-Medium";
  font-size: 16px;
}
.label-font {
  font-weight: bold;
  font-family: "SukhumvitSet-Medium";
  font-size: 14px;
}
.normal-font {
  font-family: "SukhumvitSet-Medium";
  font-size: 14px;
}
.grand-total-discount {
  font-family: "SukhumvitSet-Medium";
  font-size: 16px;
}
.table-font {
  font-family: "SukhumvitSet-Medium";
  font-size: 14px;
}

.the-black {
  color: #494949;
}

.the-blue {
  color: #4a90e2;
}
.the-green {
  color: #7ed321;
}
.the-red {
  color: #e95455;
}
.my-container {
  margin-top: 70px !important;
}
.little-comment {
  font-size: 14px;
  font-family: "SukhumvitSet-Medium";
}
.padding-left-30 {
  padding-left: 30px;
}
</style>
